import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    show_home_fab: false,
    loading: true,
    partners: [],
    careers: [],
    articles: [],
    products: {},
    panels: {},
    hero: [],
    about_image: "",
  },
  getters: {},
  mutations: {
    update_loading(state, val) {
      state.loading = val;
    },
    toggle_fab(state, val) {
      state.show_home_fab = val;
    },
    update_partners(state, val) {
      state.partners = val;
    },
    update_careers(state, val) {
      state.careers = val;
    },
    update_articles(state, val) {
      state.articles = val;
    },
    update_products(state, val) {
      state.products = val;
    },
    update_panels(state, val) {
      state.panels = val;
    },
    update_hero(state, val) {
      state.hero = val;
    },
    update_about_image(state, val) {
      state.about_image = val;
    },
  },
  actions: {
    async init({ commit }) {
      try {
        commit("update_loading", true);
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/init`,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_ACCESS_TOKEN}`, //the token is a variable which holds the token
            },
          }
        );
        const {
          partners,
          careers,
          newsblog,
          products,
          panels,
          hero,
          about_image,
        } = data;
        commit("update_partners", partners);
        commit("update_careers", careers);
        commit("update_articles", newsblog);
        commit("update_products", products);
        commit("update_panels", panels);
        commit("update_about_image", about_image.value);
        commit("update_hero", hero.value);
        setTimeout(() => {
          commit("update_loading", false);
        }, 2000);
      } catch (error) {
        // console.log("error", error);
      }
    },
    action_update_loading({ commit }, val) {
      commit("update_loading", val);
    },
  },
  modules: {},
});
