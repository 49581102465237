<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        @click="
          $router.push({
            name: 'principal-products',
            params: { name: partner.name },
          })
        "
        class="ma-4 d-flex flex-column"
        :width="200"
        :elevation="0"
        tile
      >
        <!-- :height="260" -->
        <!-- :class="`elevation-${hover ? 1 : 0}`" -->
        <v-img
          v-if="partner.image"
          style="image-rendering: auto; cursor: pointer"
          :height="breakpoint.mobile ? 84 : 100"
          :src="img_url(partner.image)"
          :lazy-src="img_url(partner.image)"
          class="d-block"
          :class="{ parners: !hover }"
          contain
          eager
        >
        </v-img>

        <v-skeleton-loader
          v-else
          class="d-block"
          :height="breakpoint.mobile ? 84 : 100"
          type="image"
        ></v-skeleton-loader>

        <v-card-title class="text-caption font-weight-bold text-center d-block">
          {{ partner.title }}
        </v-card-title>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import breakpoint from "@/mixins/breakpoint";
export default {
  mixins: [breakpoint],
  props: ["partner"],
};
</script>

<style></style>
