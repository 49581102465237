var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pb-0",class:{
    'px-6': _vm.$vuetify.breakpoint.xsOnly,
    'px-9': _vm.$vuetify.breakpoint.smOnly,
    'px-16': _vm.$vuetify.breakpoint.mdAndUp,
  },style:({ width: '100vw' }),attrs:{"fluid":""}},[_c('section',{attrs:{"id":"hero"}},[_c('keep-alive',[_c('section-hero')],1)],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('section',{staticClass:"mx-n6 mx-sm-n9 mx-md-n16",attrs:{"id":"about"}},[_c('section-about')],1),_c('section',{attrs:{"id":"products"}},[_c('keep-alive',[_c('section-products')],1)],1),_c('section',{staticClass:"mx-n6 mx-sm-n9 mx-md-n16",attrs:{"id":"news"}},[_c('section-news')],1),(true)?_c('section',{staticClass:"mx-n6 mx-sm-n9 mx-md-n16",attrs:{"id":"careers"}},[_c('section-careers')],1):_vm._e(),_c('section',{staticClass:"mx-n6 mx-sm-n9 mx-md-n16",attrs:{"id":"contact"}},[_c('section-contact')],1),_c('section',{staticClass:"mx-n6 mx-sm-n9 mx-md-n16 d-block",attrs:{"id":"partners"}},[_c('section-partners')],1)]),_c('v-overlay',{attrs:{"color":"primary lighten-3","value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"70","width":"8"}})],1),_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_home_fab),expression:"show_home_fab"}],attrs:{"elevation":"2","fab":"","dark":"","bottom":"","right":"","fixed":"","color":"theme darken-1"},on:{"click":function($event){return _vm.$vuetify.goTo(`#hero`, {
          easing: 'easeInOutCubic',
        })}}},[_c('v-icon',[_vm._v("mdi-home")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }