<template>
  <v-container
    style="margin-top: 128px"
    class="px-4 px-sm-9 px-md-16 mb-16"
    fluid
  >
    <v-card min-height="100vh" flat>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            elevation="1"
            color="theme darken-1"
            class="mt-n4"
            fab
            dark
            small
            absolute
            top
            left
            @click="back()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>

      <v-skeleton-loader
        v-if="isLoading"
        loading
        type="heading"
        class="mt-8 pa-4"
      ></v-skeleton-loader>
      <template v-else>
        <v-card-title class="px-0 px-sm-4 text-h4 font-weight-bold mt-8">
          {{ product_details.title }}
        </v-card-title>
        <v-card-subtitle
          class="px-0 px-sm-4 text-subtitle-2 theme--text font-weight-bold ml-1"
          v-if="product_details.subnote"
        >
          <span>
            {{ product_details.subnote }}
          </span>
        </v-card-subtitle>
      </template>
      <v-row class="px-0 pa-sm-8 mb-12" justify="space-around">
        <v-col cols="12" md="6" lg="5">
          <v-card flat class="px-0">
            <!-- <v-card-title class="pa-2">{{
              `${product_details.section} Analyzer`
            }}</v-card-title> -->
            <v-card-text
              style="min-height: 400px"
              class="theme--light v-sheet--outlined"
            >
              <v-skeleton-loader
                v-if="isLoading"
                loading
                style="height: 400px"
                type="image"
              ></v-skeleton-loader>
              <v-hover v-else-if="product_details.img" v-slot="{ hover }">
                <div v-if="hover" style="z-index: -1">
                  <zoom-on-hover
                    style="object-fit: contain; height: 400px"
                    :scale="2"
                    :img-normal="img_url(product_details.img)"
                  ></zoom-on-hover>
                </div>
                <v-img
                  v-else
                  height="400"
                  contain
                  aspect-ratio="1.7"
                  :src="img_url(product_details.img)"
                  eager
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-hover>
            </v-card-text>
            <v-card-actions v-if="product_details.brochure" class="pt-4">
              <v-spacer></v-spacer>
              <v-btn
                color="indigo darken-3"
                class="px-1 font-weight-bold"
                :loading="isDownloading"
                text
                plain
                small
                @click="
                  download_brochure(product_details._id, product_details.title)
                "
              >
                <v-icon> mdi-download-outline </v-icon>
                Download Brochure
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-card-text v-if="isLoading">
            <v-skeleton-loader loading type="article"></v-skeleton-loader>
            <!-- <v-skeleton-loader
              class="mt-4"
              loading
              type="sentences, paragraph@2"
            ></v-skeleton-loader> -->
          </v-card-text>
          <v-card-text
            v-else
            class="px-0"
            v-html="product_details.description"
            style="word-break: keep-all"
          >
          </v-card-text>
        </v-col>
      </v-row>
      <h3>Related Products:</h3>
      <span> <strong>Tags:</strong> {{ tags.join(", ") }}</span>
      <br />
      <v-divider class="mt-4"></v-divider>
      <v-slide-group
        :show-arrows="!$vuetify.breakpoint.mobile"
        class="py-4 mx-n4"
      >
        <template v-for="suggestion in suggestions">
          <v-slide-item
            v-if="product_details._id !== suggestion._id"
            :key="suggestion._id"
          >
            <v-hover>
              <template v-slot:default="{ hover }">
                <!-- @click="show_details(product)" -->
                <v-card
                  @click="show_details(suggestion._id)"
                  class="ma-2 d-flex flex-column"
                  :width="200"
                  :height="260"
                  :elevation="hover ? 3 : 1"
                >
                  <v-img
                    contain
                    aspect-ratio="1.7"
                    :src="img_url(suggestion.img)"
                    class="mt-2"
                    eager
                  ></v-img>

                  <v-card-title
                    class="text-caption font-weight-bold text-center d-block"
                  >
                    {{ suggestion.title }}
                  </v-card-title>
                </v-card>
              </template>
            </v-hover>
          </v-slide-item>
        </template>
      </v-slide-group>
    </v-card>
  </v-container>
</template>

<script>
import LazyProduct from "@/assets/unsplash.jpg";
import breakpoint from "../../mixins/breakpoint";
import axios from "axios";
export default {
  mixins: [breakpoint],
  data() {
    return {
      product_details: {},
      suggestions: [],
      isDownloading: false,
      isLoading: false,
      tags: [],
      LazyProduct,
    };
  },
  mounted() {
    // this.$nextTick(() => {
    window.scrollTo(0, 0);
    // });
  },
  computed: {},
  watch: {
    "$route.params.id": {
      handler: function () {
        this.fetch_product();
        this.$vuetify.goTo(0);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    show_details(id) {
      this.$router.push({ params: { id } });
    },
    download_brochure(url, filename) {
      this.isDownloading = true;
      axios({
        url: `${this.api_url}api/brochure/${url}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_ACCESS_TOKEN}`,
        },
      })
        .then((response) => {
          if (response.status == 204) {
            this.isDownloading = false;
            alert("brochure not found");
            // call update to remove brochure value in the database
          } else {
            this.isDownloading = false;
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", `${filename.trim()}.pdf`);
            document.body.appendChild(fileLink);

            fileLink.click();
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    async fetch_product() {
      this.isLoading = true;
      this.product_details = await this.$get(
        `products/${this.$route.params.id}`
      );
      this.suggestions = await this.$get(
        `sections/products/${this.product_details.section}`
      );

      this.tags = await this.product_details.panels.map((x) => {
        return x.title;
      });
      this.tags.push(this.product_details.section);
      this.isLoading = false;
    },
  },
  created() {
    this.fetch_product();
  },
};
</script>

<style>
.v-card .v-skeleton-loader__image {
  height: 400px;
}
.zoom-on-hover img {
  object-fit: contain;
  height: 400px;
}
</style>
