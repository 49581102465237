<template>
  <div
    data-aos="fade-up"
    data-aos-delay="200"
    data-aos-anchor-placement="top-center"
  >
    <v-card elevation="0" width="100%" class="mt-n10 pt-10">
      <v-card-title
        ref="products"
        class="d-block text-center text-h4 font-weight-black text-uppercase py-5"
        style="word-break: keep-all"
      >
        <!-- <h5
        class="text-subtitle-2 font-weight-bold theme--text"
        
      >
        featured products
      </h5> -->
        explore our products
      </v-card-title>
      <v-card-subtitle class="text-body-1 font-weight-medium text-center">
        Discover Our Product Line: Exceptional Solutions for Every Need.
      </v-card-subtitle>
      <v-tabs
        v-model="tab_category"
        fixed-tabs
        background-color="transparent"
        light
        color="theme"
      >
        <v-tab class="font-weight-black"> section </v-tab>
        <v-tab class="font-weight-black"> panels </v-tab>
      </v-tabs>

      <v-window
        v-model="tab_category"
        touchless
        continuous
        background-color="transparent"
      >
        <!-- Sections -->
        <v-window-item eager>
          <v-btn-toggle
            v-model="section_tab"
            @change="slide_item = null"
            tile
            color="theme"
            mandatory
            class="d-flex flex-wrap justify-center mb-5"
            group
          >
            <v-btn
              v-for="(section, key) in sections"
              :key="key"
              class="font-weight-black d-flex px-0 my-0 mx-3"
              :ripple="false"
              plain
              :small="breakpoint.mdAndDown"
            >
              <span class="text-capitalize">
                {{ section }}
              </span>
            </v-btn>
          </v-btn-toggle>
          <v-window v-model="section_tab" touchless continuous>
            <v-window-item
              v-for="section in sections"
              :key="section"
              class="pb-2"
              eager
            >
              <products-pagination
                v-if="section === 'all'"
                :products="products[section].data"
              />
              <section-items v-else :products="products" :section="section" />
            </v-window-item>
          </v-window>
        </v-window-item>
        <!-- Panels -->
        <v-window-item class="pb-2" eager>
          <panels-tab></panels-tab>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script>
// import products from "../../mixins/products.json";

import { mapState } from "vuex";
import axios from "axios";
import breakpoint from "../../../mixins/breakpoint";
import ProductsPagination from "./components/allProductsPagination.vue";
import PanelsTab from "./components/panelsTab.vue";
import SectionItems from "./components/SectionItems.vue";
export default {
  mixins: [breakpoint],
  components: {
    ProductsPagination,
    PanelsTab,
    SectionItems,
  },
  data() {
    return {
      isDownloading: false,
      tab_category: 0,
      // panels: [],
      // panel_tab: -1,
      // products: {},
      slide_item: null,
      section_tab: -1,
      box: true,
      product_details: {
        show: false,
        details: { img: "", title: "", description: "" },
      },
    };
  },
  methods: {
    download_brochure(url, filename) {
      this.isDownloading = true;
      axios({
        url: `${this.api_url}api/brochure/${url}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_ACCESS_TOKEN}`,
        },
      })
        .then((response) => {
          this.isDownloading = false;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${filename.trim()}.pdf`);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    show_details(id) {
      this.$router.push({ name: "product-details", params: { id } });
    },
  },
  async created() {
    // this.products = await this.$get("products");
    // this.panels = await this.$get("panels");
  },
  computed: {
    ...mapState(["products"]),
    sections() {
      // return this.products
      return Object.keys(this.products);
    },
    selected_section_name() {
      return this.sections[this.section_tab];
    },
    selected_section() {
      const selected_section = this.sections[this.section_tab];
      const section_items = this.products[selected_section];
      return section_items;
    },
  },
};
</script>

<style>
.v-slide-group__content {
  display: flex;
  justify-content: center;
}
.v-btn-toggle.v-btn-toggle--group .v-btn--active .v-btn__content {
  border-bottom: solid 2px #202673 !important;
  padding-bottom: 4px;
}
</style>
