<template>
  <v-container style="margin-top: 60px" class="px-0" fluid>
    <v-img
      width="100vw"
      class="bg px-5"
      height="450px"
      :lazy-src="img_url(article_details.image)"
      :src="img_url(article_details.image)"
    >
    </v-img>
    <v-card
      :width="breakpoint.mobile ? '100vw' : '70vw'"
      class="mx-auto mt-8 mt-md-16 py-10 px-5"
      tile
      flat
    >
      <v-card-title class="py-8 mt-8">
        <h1
          class="mx-auto text-center"
          style="word-break: keep-all; line-height: 125%"
        >
          {{ article_details.title }}
        </h1>
      </v-card-title>
      <v-card
        class="mx-lg-auto font-weight-medium"
        tile
        flat
        width="100%"
        v-html="article_details.body"
      >
      </v-card>
      <v-divider></v-divider>
    </v-card>
    <!-- Other open positions -->
    <v-card class="mt-12" v-if="articles.length >= 1" flat>
      <v-card-title>
        <h3 class="text-capitalize">you might also be interested in these:</h3>
      </v-card-title>
      <v-card-text class="d-flex flex-wrap justify-start">
        <template v-for="(article, key) in articles">
          <v-hover v-slot="{ hover }" :key="key">
            <v-card
              @click="show_details(article._id)"
              class="ma-2 ma-md-5"
              max-width="338"
              min-height="263"
              outlined
              :elevation="hover ? 12 : 0"
            >
              <v-img :src="img_url(article.image)" height="200px"></v-img>

              <v-card-title style="word-break: keep-all">
                {{ article.title }}
              </v-card-title>

              <v-card-subtitle>
                {{ formatDate(article.createdAt) }}
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import breakpoint from "../../mixins/breakpoint";
export default {
  mixins: [breakpoint],
  data() {
    return {
      DateTime,
      article_details: "",
      articles: [],
      chk_box_agree: false,
      isValid: false,
      suggestions: [],
      payload: {},
    };
  },
  created() {
    this.fetch_details();
  },
  computed: {},
  watch: {
    "$route.params.id": {
      handler: function () {
        this.fetch_details();
        this.$vuetify.goTo(0);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    show_details(id) {
      this.$router.push({ name: "article-details", params: { id } });
    },
    async fetch_details() {
      this.isLoading = true;
      this.article_details = await this.$get(
        `newsblog/${this.$route.params.id}`
      );
      this.articles = await this.$get(`newsblog`);
      this.articles = this.articles.filter((el) => {
        return el._id !== this.$route.params.id;
      });
      //   this.tags.push(this.product_details.section);
      this.isLoading = false;
    },
  },
};
</script>

<style>
.news-img {
  max-width: 75%;
  margin: 0 auto;
  display: flex;
}
</style>
