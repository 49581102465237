<template>
  <v-card max-width="100vw" flat tile>
    <v-card-text
      style="max-width: 1000px"
      class="text-center text-center mx-auto text-body-2 font-weight-medium"
      v-html="products[section].description"
    ></v-card-text>
    <!-- <div class="d-flex flex-md-wrap justify-md-center"> -->
    <div class="d-flex flex-wrap justify-center">
      <template v-for="(product, key) in products[section].data">
        <instrument-card
          @click="show_details(product._id)"
          :title="product.title"
          :img="img_url(product.img)"
          :key="key"
          :isLast="products[section].data.length - 1 == key"
        />
        <!-- <v-hover :key="key">
        <template v-slot:default="{ hover }">
          <v-card
            @click="show_details(product._id)"
            class="ma-2"
            :min-width="`${$vuetify.breakpoint.mobile ? '200' : '325'}`"
            :max-width="`${$vuetify.breakpoint.mobile ? '200' : '325'}`"
            :elevation="hover ? 3 : 1"
          >
            <v-img
              contain
              aspect-ratio="1.7"
              :src="api_url + product.img"
              eager
            ></v-img>

            <v-card-title
              class="text-body-2 font-weight-bold text-center d-block"
            >
              {{ product.title }}
            </v-card-title>
          </v-card>
        </template>
      </v-hover> -->
      </template>
    </div>
  </v-card>
</template>

<script>
import breakpoint from "@/mixins/breakpoint";
import InstrumentCard from "./InstrumentCard.vue";
export default {
  mixins: [breakpoint],
  props: ["products", "section"],
  components: {
    InstrumentCard,
  },
  methods: {
    show_details(id) {
      this.$router.push({ name: "product-details", params: { id } });
    },
  },
};
</script>

<style></style>
