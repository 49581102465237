<template>
  <div>
    <v-card
      class="py-10 px-0 mx-auto contact-container"
      :class="{ 'rounded-xl': breakpoint.mdAndUp }"
      color="theme lighten-1"
      tile
      :max-width="breakpoint.mdAndUp ? '60%' : '100%'"
      elevation="0"
    >
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-delay="100"
      >
        <v-card-title
          class="text-uppercase d-block text-h4 text-center font-weight-black white--text"
          style="word-break: keep-all"
        >
          <div
            class="text-capitalize text-body-1 font-weight-medium white--text"
          >
            do you have any question?
          </div>
          feel free to contact us
        </v-card-title>
        <v-card-subtitle
          class="text-body-1 font-weight-medium text-center white--text"
        >
          Let's Connect and Make Things Happen
        </v-card-subtitle>
      </div>

      <!-- d-block mx-4 mx-sm-auto ml-md-0 -->
      <v-card
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-delay="200"
        class="py-5 pa-8 mt-4 mx-auto"
        :class="{ 'rounded-lg': breakpoint.mdAndUp }"
        tile
        max-width="450"
      >
        <v-form ref="form" v-model="isValid" lazy-validation>
          <v-text-field
            v-model="payload.name"
            :rules="[(v) => !!v || 'Name is required']"
            label="Full Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="payload.email"
            :rules="[
              (v) => !!v || 'E-mail is required',
              (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ]"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            v-model="payload.contact"
            :rules="[
              (v) => !!v || 'Phone Number is required',
              (v) =>
                /^(9|09|\+639)\d{9}$/gm.test(v) || 'Phone Number must be valid',
            ]"
            label="Phone Number"
            required
          ></v-text-field>
          <v-textarea
            v-model="payload.message"
            label="Message"
            row-height="20"
            counter
            maxlength="300"
            rows="4"
            :rules="[(v) => !!v || 'Message is required']"
            no-resize
            required
          ></v-textarea>
          <!-- (v) => !!v || 'Message is required', -->
          <v-checkbox
            class="pt-2"
            v-model="chk_box_agree"
            :rules="[(v) => !!v || 'You must agree to continue!']"
            label=" "
            required
            dense
          >
            <template v-slot:label>
              <div class="text-body-2">
                I've read and accept the
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="/privacy-policy"
                      @click.stop
                      v-on="on"
                    >
                      Privacy Policy.
                    </a>
                  </template>
                  Read our Privacy Policy.
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
          <v-btn
            color="indigo white--text"
            elevation="0"
            block
            large
            @click="send_message()"
            class="mr-4 mt-4"
            :loading="loading"
          >
            Send Message
          </v-btn>
        </v-form>
      </v-card>
      <v-card-actions
        data-aos="fade"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay="300"
        class="d-flex flex-wrap align-center justify-space-around mx-8 px-8 pt-8"
      >
        <div class="d-flex">
          <v-card flat dark color="transparent">
            <v-card-title
              class="text-h6 pb-0 font-weight-bold text-uppercase d-block"
            >
              <v-icon class="mr-2">mdi-email-outline</v-icon>
              Send Us a Message
            </v-card-title>
            <v-card-text class="text-body-2 text-sm-body-1">
              mdmpi@marsmandrysdale.com
            </v-card-text>
          </v-card>
        </div>

        <div class="d-flex">
          <v-card flat dark color="transparent">
            <v-card-title
              class="text-h6 pb-0 font-weight-bold text-uppercase d-block"
            >
              <v-icon class="mr-2">mdi-phone-outline</v-icon>
              Call or Text Us
            </v-card-title>
            <v-card-text class="text-body-2 text-sm-body-1">
              (+63) 908 888 0000
            </v-card-text>
          </v-card>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// const recaptcha = this.$recaptchaInstance;
// recaptcha.hideBadge();
import captcha from "../../mixins/captcha";
import breakpoint from "../../mixins/breakpoint";
export default {
  mixins: [captcha, breakpoint],
  data() {
    return {
      chk_box_agree: false,
      isValid: false,
      loading: false,
      payload: {},
    };
  },
  computed: {
    host() {
      return window.location.host;
    },
  },
  methods: {
    clear_fields() {
      this.payload = {};
      this.chk_box_agree = false;
      this.$refs.form.reset();
    },
    async send_message() {
      // await this.$refs.form.reset();
      await this.$refs.form.validate();
      if (this.isValid) {
        this.loading = true;
        const token = await this.recaptcha();
        this.$axios.setHeader({ captcha: token });
        const mailer = await this.$post("sendEmail/contactus", this.payload);
        // console.log(mailer);
        if (mailer.message == "success") {
          this.clear_fields();
        }
        this.$axios.deleteHeader("captcha");
        this.loading = false;
      }
    },
  },
  mounted() {},
};
</script>

<style>
#contact .v-card.contact-container {
  min-height: 50%;
  background: linear-gradient(45deg, #3d3c8d5b 0%, #3d3c8d 55%),
    url("@/assets/envelope.png");
  /* background-size: 60px;
   */
  background-repeat: no-repeat;
  background-position: 15px 35%;
}
</style>
