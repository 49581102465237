<template>
  <v-card flat>
    <div class="text-center">
      <v-card-title class="d-block font-weight-bold text-h6">
        MDMPI Diagnostic Panels
      </v-card-title>
      <v-card-subtitle>
        (Based on Disease Management or Diagnostic Product)
      </v-card-subtitle>
    </div>
    <v-card-text>
      <v-btn-toggle
        v-model="panel_tab"
        tile
        color="theme"
        mandatory
        class="d-flex flex-wrap justify-center mb-5"
        group
      >
        <template v-for="(panel, key) in panels">
          <v-btn
            v-if="panel.groups.length >= 1"
            :key="key"
            class="font-weight-black text-capitalize d-flex px-0 my-0 mx-2"
            :ripple="false"
            plain
            :small="breakpoint.mdAndDown"
          >
            {{ panel.title }}
          </v-btn>
        </template>
      </v-btn-toggle>
      <v-tabs-items v-model="panel_tab" touchless continuous>
        <template v-for="(panel, key) in panels">
          <v-tab-item
            :key="key"
            v-if="panel.groups.length >= 1"
            class="pb-2"
            eager
          >
            <v-card max-width="100vw" flat tile>
              <!-- <v-card flat class="d-block"> -->
              <v-card-title class="text-center d-block mb-8">
                <h1
                  class="d-flex flex-wrap mx-auto align-center justify-center"
                >
                  <v-img
                    class="rounded-lg"
                    min-width="125"
                    min-height="125"
                    max-width="125"
                    max-height="125"
                    aspect-ratio="1"
                    :src="img_url(panel.img)"
                    eager
                  ></v-img>
                  <span class="mx-4 mx-sm-8">
                    {{ panel.title }}
                  </span>
                </h1>
              </v-card-title>
              <!-- <v-card-text
              v-if="panel.description && panel.description !== '<p></p>'"
                style="max-width: 1000px;
                class="text-left mx-auto text-body-2 font-weight-medium"
                v-html="panel.description"
              ></v-card-text> -->
              <!-- </v-card> -->
              <!-- <v-row
                :style="{
                  'max-width': breakpoint.mdAndUp ? '980px' : '100vw',
                }"
                no-gutters
                class="mx-auto mb-4"
              >
                <v-col offset-lg="2" lg="4">
                  <h4 v-if="breakpoint.lgAndUp" class="mb-2">Parameters</h4>
                </v-col>
                <v-col cols="12" lg="6" class="my-auto">
                  <h4 v-if="breakpoint.lgAndUp" class="mb-2">Analyzers</h4>
                </v-col>
              </v-row> -->
              <template v-for="(group, key) in panel.groups">
                <div
                  :key="key"
                  :style="{
                    'max-width': breakpoint.mdAndUp ? '980px' : '100vw',
                  }"
                  class="mx-auto mb-4"
                >
                  <v-divider class="pb-4"></v-divider>
                  <v-row no-gutters>
                    <v-col cols="12" align-self="center">
                      <div class="d-flex flex-wrap justify-center pa-1">
                        <span
                          class="text-caption text-sm-body-2 font-weight-medium pa-1"
                          v-for="(param, key) in group.parameters"
                          :key="key"
                        >
                          <v-icon> mdi-circle-small </v-icon>
                          <span class="text-capitalize">
                            {{ param }}
                          </span>
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="12" class="my-auto">
                      <div class="d-flex flex-wrap justify-center">
                        <template v-for="(product, key) in group.products">
                          <instrument-card
                            @click="show_details(product._id)"
                            :title="product.title"
                            :img="img_url(product.img)"
                            :key="key"
                            :isLast="group.products.length - 1 == key"
                        /></template>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-card>
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import breakpoint from "@/mixins/breakpoint";
import InstrumentCard from "./InstrumentCard.vue";
export default {
  mixins: [breakpoint],
  data() {
    return {
      // panels: [],
      panel_tab: -1,
    };
  },
  components: {
    InstrumentCard,
  },
  // async created() {
  //   this.panels = await this.$get("panels");
  // },
  methods: {
    show_details(id) {
      this.$router.push({ name: "product-details", params: { id } });
    },
  },
  computed: {
    ...mapState(["panels"]),
  },
};
</script>

<style></style>
