<template>
  <!-- <v-card
    class="d-flex align-center"
    color="theme lighten-1"
    tile
    elevation="0"
  > -->
  <v-container fluid class="mx-auto partners_container pt-8 py-15">
    <v-row class="mt-n15" justify="center" align-content="center">
      <v-col
        class="d-flex align-center mt-15"
        cols="12"
        style="flex-basis: fit-content"
      >
        <v-card
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          color="transparent"
          elevation="0"
          class="d-flex flex-column"
        >
          <!-- :class="{ '': breakpoint.xs }" -->
          <v-card-title
            class="text-center mx-auto py-5"
            style="word-break: keep-all"
          >
            <h4 class="text-h4 font-weight-black text-uppercase">
              we work with the
              <span class="theme--text text-uppercase"> best </span> partners
            </h4>
          </v-card-title>
          <v-card-text
            class="text-body-1 font-weight-medium text-center"
            style="max-width: 650px"
          >
            <p>
              MDMPI has been serving the healthcare industry in the Philippines
              for several years and has established partnerships with various
              global healthcare manufacturers
            </p>
            <!-- <p>
              For several years MDMPI served different hospitals and clinical
              laboratories by providing different analyzers from the following
              principals
            </p> -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" style="">
        <v-card
          color="transparent"
          elevation="0"
          class="d-flex align-center flex-wrap justify-space-around mx-auto"
          :width="!$vuetify.breakpoint.mobile ? '60vw' : 'unset'"
          :height="!$vuetify.breakpoint.mobile ? '80vh' : 'unset'"
        >
          <!-- <div
            v-for="partner in partners"
            :key="partner.name"
            class="pa-2 my-15"
          > -->
          <!-- {{ partner.name }} -->
          <!-- :src="`@/assets/partners/${partner.img}`" -->
          <!-- max-width="300"
              max-height="100" -->
          <v-card
            @click="
              $router.push({
                name: 'principal-products',
                params: { name: partner.name },
              })
            "
            data-aos="fade-up"
            data-aos-anchor-placement="center"
            :data-aos-delay="100 * key"
            data-aos-duration="250"
            color="transparent"
            v-for="(partner, key) in partners"
            :key="partner.name"
            flat
            class="ma-2 ma-md-4 my-4"
            :class="`card-${key}`"
            :max-width="$vuetify.breakpoint.mobile ? 200 : 280"
            :min-width="200"
          >
            <v-hover v-slot="{ hover }">
              <!-- :style="{ filter: 'grayscale(1)', opacity: 0.5 }" -->
              <v-img
                v-if="partner.image"
                style="image-rendering: auto; cursor: pointer"
                :height="$vuetify.breakpoint.mobile ? 84 : 100"
                :src="img_url(partner.image)"
                :lazy-src="img_url(partner.image)"
                class="d-block"
                :class="{ parners: !hover }"
                contain
                eager
              >
              </v-img>
            </v-hover>
            <!-- <span class="black--text">
              {{ key }}
            </span> -->
          </v-card>
          <!-- </div> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- </v-card> -->
</template>

<script>
import { mapState } from "vuex";
import breakpoint from "../../mixins/breakpoint";
export default {
  mixins: [breakpoint],
  data() {
    return {};
  },
  computed: {
    ...mapState(["partners"]),
  },
};
</script>

<style>
#partners {
  position: relative;
}
.partners_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      155deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 0.95) 60%,
      rgba(255, 255, 255, 0.5) 100%
    ),
    url("@/assets/testtube.jpg");
  filter: grayscale(1);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parners {
  filter: grayscale(1);
  opacity: 0.65;
}
</style>
