<template>
  <v-container style="margin-top: 60px" class="px-0" fluid>
    <v-img
      gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.8)"
      width="100vw"
      class="bg px-5 cover-image"
      min-height="250px"
      max-height="275px"
      cover
      :lazy-src="banner"
      :src="banner"
    >
      <v-card
        flat
        color="transparent"
        class="d-flex justify-start align-center mx-auto"
        height="100%"
        max-width="680px"
        :style="{
          textShadow: '0 0 20px #000000',
        }"
      >
        <h4 class="text-h4 white--text font-weight-bold">
          {{ career_details.title }}
          <div>
            <v-chip class="ma-1 px-4 text-uppercase" dark label outlined>
              <v-icon small left> mdi-map-marker-outline </v-icon>
              {{ career_details.location }}
            </v-chip>
            <v-chip class="ma-1 px-4 text-uppercase" dark label outlined>
              <v-icon small left> mdi-account-group-outline </v-icon>
              {{ career_details.department }}
            </v-chip>
          </div>
        </h4>
      </v-card>
    </v-img>
    <v-card width="680px" class="mx-auto mt-8 mt-md-16 py-10 px-5" tile flat>
      <v-card tile flat v-html="career_details.body" />
      <v-card-text class="mt-8 pa-8 theme--light v-sheet--outlined rounded-lg">
        <v-card-title class="font-weight-medium"
          >Apply For This Position</v-card-title
        >
        <v-form ref="form" v-model="isValid" lazy-validation>
          <v-text-field
            v-model="payload.name"
            :rules="[(v) => !!v || 'Name is required']"
            label="Full Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="payload.email"
            :rules="[
              (v) => !!v || 'E-mail is required',
              (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ]"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            v-model="payload.contact"
            :rules="[
              (v) => !!v || 'Phone Number is required',
              (v) =>
                /^(9|09|\+639)\d{9}$/gm.test(v) || 'Phone Number must be valid',
            ]"
            label="Phone Number"
            required
          ></v-text-field>
          <v-file-input
            @change="input_log()"
            v-model="payload.attachment"
            accept="application/doc, application/docx, application/pdf"
            label="Attach Resume"
            :rules="[(v) => !!v || 'Resume is required']"
          ></v-file-input>
          <v-checkbox
            class="pt-2"
            v-model="chk_box_agree"
            :rules="[(v) => !!v || 'You must agree to continue!']"
            label=" "
            required
            dense
          >
            <template v-slot:label>
              <div class="text-body-2">
                I've read and accept the
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="/privacy-policy"
                      @click.stop
                      v-on="on"
                    >
                      Privacy Policy.
                    </a>
                  </template>
                  Read our Privacy Policy.
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
          <v-btn
            color="indigo white--text"
            elevation="0"
            block
            large
            @click="send_application()"
            :loading="loading"
            class="mr-4 mt-4"
          >
            Send Application
          </v-btn>
        </v-form>
      </v-card-text>
      <!-- Other open positions -->
      <v-card class="mt-12" v-if="suggestions.length >= 1" flat>
        <v-card-title>
          <h3>Other open positions:</h3>
        </v-card-title>
        <v-card-text class="d-flex flex-wrap justify-start">
          <template v-for="(career, key) in suggestions">
            <v-hover :key="key">
              <template v-slot:default="{ hover }">
                <v-card
                  class="ma-4"
                  min-width="250"
                  max-width="350"
                  :elevation="hover ? 3 : 0"
                  @click="getDetails(career._id)"
                  outlined
                  tile
                >
                  <v-card-title class="text-body-2 font-weight-bold">
                    {{ career.title }}
                  </v-card-title>
                  <v-card-subtitle class="text-uppercase">
                    <div>
                      <v-icon dense small>mdi-account-group </v-icon>
                      {{ career.department }}
                    </div>
                    <div>
                      <v-icon dense small> mdi-map-marker </v-icon>
                      {{ career.location }}
                    </div>
                  </v-card-subtitle>
                </v-card>
              </template>
            </v-hover>
          </template>
        </v-card-text>
      </v-card>
    </v-card>
    <!-- mdi-account-group-outline -->
  </v-container>
</template>

<script>
// import banner from "@/assets/career-handshake.jpg";
import banner from "@/assets/Carousel_Rightads.jpg";
import captcha from "../../mixins/captcha";
export default {
  mixins: [captcha],
  data() {
    return {
      banner,
      career_details: "",
      chk_box_agree: false,
      isValid: false,
      suggestions: [],
      loading: false,
      payload: {},
    };
  },
  created() {
    this.fetch_details();
  },
  computed: {},
  watch: {
    "$route.params.id": {
      handler: function () {
        this.fetch_details();
        this.$vuetify.goTo(0);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    input_log() {
      // console.log(this.payload.attachment.name.split(".").pop());
    },
    clear_fields() {
      this.payload = {};
      this.chk_box_agree = false;
      this.$refs.form.reset();
    },
    async send_application() {
      await this.$refs.form.validate();
      if (this.isValid) {
        this.loading = true;
        const token = await this.recaptcha();
        const fd = new FormData();
        fd.append("captcha", token);
        fd.append("name", this.payload.name);
        fd.append("email", this.payload.email);
        fd.append("contact", this.payload.contact);
        fd.append("position", this.career_details.title);
        fd.append("location", this.career_details.location);
        fd.append(
          "resume",
          this.payload.attachment,
          `${this.payload.name}-Resume.${this.payload.attachment.name
            .split(".")
            .pop()}`
        );
        this.$axios.setHeader({ captcha: token });
        const mailer = await this.$post("sendEmail/application", fd);
        console.log(mailer);
        if (mailer.message == "success") {
          this.clear_fields();
        }
        this.$axios.deleteHeader("captcha");
        this.loading = false;
      }
    },
    getDetails(id) {
      this.$router.push({ name: "career-details", params: { id } });
    },
    async fetch_details() {
      this.isLoading = true;
      this.career_details = await this.$get(`careers/${this.$route.params.id}`);
      this.suggestions = await this.$get(`careers`);
      this.suggestions = this.suggestions.filter((el) => {
        return el._id !== this.$route.params.id;
      });
      //   this.tags.push(this.product_details.section);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.cover-image {
  .v-image__image {
    background-position: top center !important;
  }
}
</style>
