<template>
  <v-container
    style="margin-top: 128px"
    fluid
    class="px-4 px-sm-9 px-md-16 mb-16"
  >
    <v-card
      :width="breakpoint.smAndDown ? '100vw' : '70vw'"
      flat
      class="mx-auto"
    >
      <v-card-title class="d-flex flex-column my-8">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="1"
              color="theme darken-1"
              class="mt-n4"
              fab
              dark
              small
              absolute
              top
              left
              @click="$router.go(-1)"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <v-img
          v-if="cur_partner && cur_partner.image"
          contain
          eager
          :height="$vuetify.breakpoint.mobile ? 184 : 200"
          :src="img_url(cur_partner.image)"
          :lazy-src="img_url(cur_partner.image)"
          class="mt-2"
        ></v-img>

        <v-skeleton-loader
          v-else
          class="mt-2"
          :height="$vuetify.breakpoint.mobile ? 184 : 200"
          type="image"
        ></v-skeleton-loader>
      </v-card-title>
      <v-card width="100%" class="rounded-lg" flat color="theme" dark>
        <v-card-title
          v-if="cur_partner && cur_partner.name"
          class="text-capitalize font-weight-medium text-h6 py-2 my-4"
        >
          {{ cur_partner.name }}
        </v-card-title>
      </v-card>
      <div
        v-if="products"
        class="d-flex flex-wrap align-start justify-center justify-md-space-around align-center overflow-x-auto mx-auto mb-12"
      >
        <template v-for="(product, key) in products">
          <v-hover :key="key">
            <template v-slot:default="{ hover }">
              <!-- @click="show_details(product)" -->
              <v-card
                @click="show_details(product._id)"
                class="ma-2 d-flex flex-column flex-grow-0 flex-shrink-0"
                :width="breakpoint.xs ? 'auto' : 270"
                :min-height="265"
                flat
                :outlined="!hover"
                :elevation="hover ? 2 : 0"
              >
                <!-- :style="{ position: hover ? 'absolute' : 'unset' }" -->
                <!-- :height="270" -->
                <v-img
                  contain
                  aspect-ratio="1.7"
                  :src="img_url(product.img)"
                  class="mt-2"
                  eager
                ></v-img>

                <v-card-title
                  class="text-subtitle-2 font-weight-bold grey--text text--darken-3 text-center d-block"
                >
                  <p>
                    {{ product.title }}
                  </p>
                </v-card-title>

                <v-expand-transition>
                  <!-- <v-card
                    v-if="!hover"
                    class="mb-0"
                    style="
                      position: absolute;
                      background: white;
                      top: 95%;
                      z-index: 99;
                    "
                  > -->
                  <!-- <v-card-text v-if="hover" class="mb-0">
                    <p><strong>Quick Overview</strong></p>
                    <p
                      class="overview"
                      v-html="parseBody(product.description)"
                    ></p>
                  </v-card-text> -->
                  <!-- </v-card> -->
                </v-expand-transition>
              </v-card>
            </template>
          </v-hover>
        </template>
      </div>
    </v-card>
    <div>
      <h3>Other Principals:</h3>
      <v-divider class="mt-4"></v-divider>

      <!-- <v-slide-group v-if="!breakpoint.mobile" class="py-4 mx-n4">
        <template v-for="partner in partners">
          <v-slide-item
            v-if="partner.name !== $route.params.name"
            :key="partner._id"
          >
            <principalsFlex :partner="partner" />
          </v-slide-item>
        </template>
      </v-slide-group> -->
      <div class="py-4 mx-n4 d-flex flex-wrap justify-center align-baseline">
        <template v-for="partner in partners">
          <principalsFlex
            v-if="partner.name !== $route.params.name"
            :partner="partner"
            :key="partner._id"
          />
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import principalsFlex from "@/components/principalsFlex.vue";
import breakpoint from "../../mixins/breakpoint";
export default {
  mixins: [breakpoint],
  components: { principalsFlex },
  data() {
    return {
      products: [],
    };
  },
  methods: {
    show_details(id) {
      this.$router.push({ name: "product-details", params: { id } });
    },
    parseBody(body) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(body, "text/html");
      return doc.getElementsByTagName("p")[1].innerHTML;
    },
    async fetch_product() {
      //   window.scrollTo(0, 0);
      this.products = await this.$get(
        `partners/products/${encodeURI(this.$route.params.name)}`
      );
    },
  },
  async mounted() {
    this.$nextTick(() => {});
  },
  watch: {
    "$route.params.id": {
      handler: function () {
        this.fetch_product();
        this.$vuetify.goTo(0);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState(["partners"]),
    cur_partner() {
      return this.partners.filter((x) => {
        return x.name == this.$route.params.name;
      })[0];
    },
  },
};
</script>

<style scoped>
.overview {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
