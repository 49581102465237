<template>
  <v-container
    :class="{
      'px-6': $vuetify.breakpoint.xsOnly,
      'px-9': $vuetify.breakpoint.smOnly,
      'px-16': $vuetify.breakpoint.mdAndUp,
    }"
    :style="{ width: '100vw' }"
    class="pb-0"
    fluid
  >
    <section id="hero">
      <keep-alive>
        <section-hero></section-hero>
      </keep-alive>
    </section>
    <span v-show="!loading">
      <section id="about" class="mx-n6 mx-sm-n9 mx-md-n16">
        <section-about></section-about>
      </section>
      <section id="products">
        <keep-alive>
          <section-products></section-products>
        </keep-alive>
      </section>
      <!-- <template>
      <section-lis class="lis"></section-lis>
    </template> -->
      <section id="news" class="mx-n6 mx-sm-n9 mx-md-n16">
        <section-news></section-news>
      </section>
      <section v-if="true" id="careers" class="mx-n6 mx-sm-n9 mx-md-n16">
        <section-careers></section-careers>
      </section>
      <section id="contact" class="mx-n6 mx-sm-n9 mx-md-n16">
        <section-contact></section-contact>
      </section>

      <section id="partners" class="mx-n6 mx-sm-n9 mx-md-n16 d-block">
        <section-partners></section-partners>
      </section>
    </span>
    <v-overlay color="primary lighten-3" :value="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        size="70"
        width="8"
      ></v-progress-circular>
    </v-overlay>

    <v-fab-transition>
      <v-btn
        v-show="show_home_fab"
        @click="
          $vuetify.goTo(`#hero`, {
            easing: 'easeInOutCubic',
          })
        "
        elevation="2"
        fab
        dark
        bottom
        right
        fixed
        color="theme darken-1"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- <v-btn fab dark large color="primary" fixed right bottom>
      <v-icon dark>add</v-icon>
    </v-btn> -->
  </v-container>
</template>
<script>
// components
import SectionHero from "@/components/sections/SectionHero.vue";
import SectionProducts from "@/components/sections/SectionProducts/";
import SectionAbout from "@/components/sections/SectionAbout.vue";
import SectionPartners from "@/components/sections/SectionPartners.vue";
import SectionContact from "@/components/sections/SectionContact.vue";
// import SectionLis from "@/components/sections/SectionLis.vue";
import SectionNews from "@/components/sections/SectionNews.vue";
import SectionCareers from "@/components/sections/SectionCareers.vue";

import { mapState } from "vuex";
export default {
  name: "HomeView",
  components: {
    SectionHero,
    SectionProducts,
    SectionAbout,
    SectionPartners,
    SectionContact,
    // SectionLis,
    SectionNews,
    SectionCareers,
  },
  data() {
    return {
      load: {},
      sectionObserver: null,
    };
  },
  created() {},
  computed: {
    ...mapState(["show_home_fab", "loading"]),
  },
  methods: {
    // ...mapActions(["action_update_loading"]),
    // observeSections() {
    //   // try {
    //   //   this.sectionObserver.disconnect();
    //   // } catch (error) {
    //   //   console.log(error);
    //   // }
    //   const options = {
    //     rootMargin: "0px 0px -90% 0px",
    //     threshold: 0,
    //   };
    //   this.sectionObserver = new IntersectionObserver(
    //     this.sectionObserverHandler,
    //     options
    //   );
    //   // Observe each section
    //   const sections = document.querySelectorAll("section");
    //   sections.forEach((section) => {
    //     this.sectionObserver.observe(section);
    //   });
    // },
    // sectionObserverHandler(entries) {
    //   for (const entry of entries) {
    //     if (entry.intersectionRatio > 0) {
    //       const sectionId = entry.target.id;
    //       if (this.$route.hash !== `#${sectionId}`) {
    //         this.$router.push({
    //           name: this.$route.name,
    //           hash: `#${sectionId}`,
    //         });
    //       }
    //     }
    //   }
    // },
  },
  mounted() {
    // window.addEventListener("load", this.action_update_loading(false));
    // this.observeSections();
    // });
  },
};
</script>
<style lang="scss"></style>
<style scoped>
section:not(#hero, #partners) {
  margin: 85px 0;
  padding-top: 50px;
}
</style>
