<template>
  <v-footer dark padless class="">
    <section-map v-show="showMap"></section-map>
    <v-card
      flat
      tile
      width="100%"
      class="d-flex flex-column white--text text-left pt-5 spacer layer1"
      id="footer"
      color="#232379"
    >
      <v-row
        class="py-2 px-4 px-md-8 mt-4 flex-row justify-space-around align-center"
        no-gutters
      >
        <v-col cols="12" style="flex-basis: fit-content" class="align-center">
          <v-card color="transparent" elevation="0" tile>
            <v-card-title class="">
              <!-- :src="require(`@/assets/logo-outline.png`)" -->
              <v-img
                :src="require(`@/assets/logo-white.png`)"
                :max-width="breakpoint.xsOnly ? 300 : 400"
                width="100%"
                contain
                eager
              >
                <!--  -->
              </v-img>
            </v-card-title>
            <v-card-text
              class="px-sm-6 py-4 px-4"
              :style="{
                'max-width': breakpoint.xsOnly ? '350px' : '400px',
              }"
            >
              <p class="text-justify mx-auto">
                We nurture a work environment where teamwork, creativity and
                growth are encouraged.
              </p>
              <div>
                <p class="text-subtitle-2 mb-1">follow us</p>
                <div class="d-flex align-center flex-wrap justify-start">
                  <v-hover
                    v-slot="{ hover }"
                    v-for="social in socials"
                    :key="social.icon"
                  >
                    <v-btn
                      class="mx-4 white--text"
                      @click="url_open(social.url)"
                      icon
                      :style="{ opacity: hover ? 1 : 0.7 }"
                    >
                      <v-icon size="24px">
                        {{ social.icon }}
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="d-flex flex-wrap justify-start px-3"
          style="flex-basis: fit-content"
        >
          <v-card
            class="mx-4"
            :max-width="breakpoint.xsOnly ? 350 : 400"
            color="transparent"
            elevation="0"
            tile
          >
            <v-card-title class="pb-0 font-weight-bold text-uppercase">
              <v-icon small class="mr-2">mdi-office-building-outline</v-icon>
              <h6 class="text-uppercase white--text">meet us at the office</h6>
            </v-card-title>
            <v-card-subtitle
              class="pt-2 flex-wrap"
              style="word-break: keep-all"
            >
              Ground Floor, Molave Building 2231 Chino Roces Avenue, Makati City
              1231
            </v-card-subtitle>
          </v-card>

          <v-card class="mx-4" color="transparent" elevation="0" tile>
            <v-card-title class="pb-0 font-weight-bold text-uppercase">
              <v-icon small class="mr-2">mdi-email-outline</v-icon>

              <h6 class="text-uppercase white--text">Send Us a Message</h6>
            </v-card-title>
            <v-card-subtitle class="pt-2">
              mdmpi@marsmandrysdale.com
            </v-card-subtitle>
          </v-card>

          <v-card class="mx-4" color="transparent" elevation="0" tile>
            <v-card-title class="pb-0 font-weight-bold text-uppercase">
              <v-icon small class="mr-2">mdi-phone-outline</v-icon>
              <h6 class="text-uppercase white--text">Call or Text Us</h6>
            </v-card-title>
            <v-card-subtitle class="pt-2"> +63.908.888.0000 </v-card-subtitle>
          </v-card>
        </v-col>

        <!-- <v-col
          cols="12"
          class="text-center pa-4"
          style="flex-basis: fit-content; min-width: 250px"
        >
          <h5 class="text-uppercase white--text">follow us</h5>
          <div class="d-flex align-center flex-wrap justify-space-around mt-5">
            <v-btn
              v-for="social in socials"
              :key="social.icon"
              class="mx-4 white--text"
              @click="url_open(social.url)"
              icon
            >
              <v-icon size="24px">
                {{ social.icon }}
              </v-icon>
            </v-btn>
          </div>
        </v-col> -->
      </v-row>

      <v-card-text class="text-center">
        <div>
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          apply.
        </div>
        {{ new Date().getFullYear() }} — ©<strong> I.M.S</strong> Marsman
        Drysdale Medical Products, Inc.
        <!-- All rights reserved. -->
        <br />
        <span v-if="visit && visit.count">Visit count: {{ visit.count }}</span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
import breakpoint from "../mixins/breakpoint";
import SectionMap from "@/components/sections/SectionMap.vue";
export default {
  mixins: [breakpoint],
  components: {
    SectionMap,
  },
  computed: {
    showMap() {
      if (["home", "about"].includes(this.$route.name)) return true;
      else return false;
    },
  },
  methods: {
    url_open(url) {
      window.open(url);
    },
  },
  async created() {
    console.log("created");
    this.visit = await this.$get("visit");
  },
  data: () => ({
    visit: null,
    icons: ["mdi-facebook", "mdi-linkedin", "mdi-instagram"],
    socials: [
      {
        icon: "mdi-facebook",
        url: "https://www.facebook.com/MDMedicalCares",
      },
      // {
      //   icon: "mdi-linkedin",
      //   url: "https://www.linkedin.com/company/marsman-drysdale-medical-products-inc./",
      // },
      // {
      //   icon: "mdi-instagram",
      //   url: "https://www.instagram.com/explore/locations/273288410/marsman-drysdale-medical-products-inc",
      // },
      // {
      //   icon: "mdi-alpha-j-box-outline",
      //   url: "https://www.jobstreet.com.ph/en/companies/508256-marsman-drysdale-medical-products-inc",
      // },
    ],
  }),
};
</script>
<style scoped>
.spacer {
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.layer1 {
  /* background-image: url("../assets/layered-waves-haikei.svg"); */
}
</style>
