<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        @click="$emit('click')"
        class="ma-0 ma-sm-2 flex-grow-1 flex-sm-grow-0"
        :width="`${$vuetify.breakpoint.xs ? '150' : '325'}`"
        :elevation="$vuetify.breakpoint.xs ? 0 : hover ? 3 : 1"
        :max-width="`${$vuetify.breakpoint.xs && isLast ? '45vw' : 'unset'}`"
      >
        <v-img v-if="img" contain aspect-ratio="1.7" :src="img" eager>
          <!-- {{ product.title }} -->
        </v-img>

        <v-card-title
          v-if="title"
          class="d-block text-sm-body-2 text-caption text-center font-weight-bold"
        >
          {{ title }}
        </v-card-title>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  props: ["title", "img", "isLast"],
};
</script>

<style></style>
