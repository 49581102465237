<template>
  <div class="mapouter">
    <div class="gmap_canvas">
      <iframe
        width="100%"
        height="100%"
        id="gmap_canvas"
        :src="src"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionMap",
  data() {
    return {
      src: "https://maps.google.com/maps?q=Molave%20Building%20&t=&z=17&ie=UTF8&iwloc=&output=embed",
      // src: "https://maps.google.com/maps?q=79rH4VuF7pu2pGg78?coh=178572&entry=tt&t=&z=17&ie=UTF8&iwloc=&output=embed",
      // src: "https://maps.google.com/maps?q=MARSMAN%20DRYSDALE%20MEDICAL%20PRODUCTS&output=embed",
    };
  },
};
</script>
<style>
.mapouter {
  position: relative;
  text-align: right;
  height: 450px;
  width: 100vw;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 450px;
  width: 100vw;
}
</style>
