import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPT_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
    badge: "bottomleft",
  },
});
// const recaptcha = this.$recaptchaInstance;
export default {
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");

      // Hide reCAPTCHA badge:
      //   recaptcha.hideBadge();

      // Show reCAPTCHA badge:
      //   console.log(token);
      return token;
      // Do stuff with the received token.
    },
  },
};
