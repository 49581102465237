import { DateTime } from "luxon";
export default {
  data: () => ({
    DateTime,
  }),
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat("ff");
    },
    img_url(url) {
      return `${process.env.VUE_APP_API_URL.replace("api", "")}${url.replaceAll(
        "\\",
        "/"
      )}`;
    },
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
    api_url() {
      return process.env.VUE_APP_API_URL.replace("api", "");
    },
  },
};
