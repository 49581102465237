<template>
  <v-card flat>
    <v-card-actions>
      <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
      <v-pagination
        color="theme"
        class="pa-0 mx-auto"
        v-model="page"
        circle
        :length="products.length"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </v-card-actions>
    <v-tabs-items v-model="page" touchless>
      <v-tab-item v-for="(tab, key) in products" :key="key" :value="key + 1">
        <v-card
          max-width="100vw"
          class="d-flex flex-wrap justify-center"
          flat
          tile
        >
          <template v-for="(product, key) in tab">
            <instrument-card
              @click="show_details(product._id)"
              :title="product.title"
              :img="img_url(product.img)"
              :key="key"
              :isLast="tab.length - 1 == key"
            />
          </template>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import InstrumentCard from "./InstrumentCard.vue";
import breakpoint from "@/mixins/breakpoint";

export default {
  mixins: [breakpoint],
  data() {
    return {
      page: 1,
    };
  },
  components: {
    InstrumentCard,
  },
  methods: {
    show_details(id) {
      this.$router.push({ name: "product-details", params: { id } });
    },
  },
  props: ["products"],
  computed: {
    break() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style>
.v-pagination button {
  box-shadow: unset !important;
}
</style>
