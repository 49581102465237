<template>
  <v-card
    color="theme lighten-1 news__container"
    tile
    elevation="0"
    class="mx-0 px-0 py-15"
    min-height="90vh"
  >
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-delay="100"
    >
      <v-card-title
        class="text-uppercase d-block text-center"
        style="word-break: keep-all"
      >
        <h4 class="text-h4 font-weight-black white--text mb-2">
          NEWS & ARTICLES
        </h4>
      </v-card-title>
      <v-card-subtitle class="white--text text-center text-body-1">
        Stay Updated with the Latest News and Updates
      </v-card-subtitle>
      <v-card-text
        class="d-flex justify-center align-start flex-wrap fill-height"
      >
        <template v-for="(article, key) in articles">
          <v-hover v-slot="{ hover }" :key="key">
            <v-card
              @click="show_details(article._id)"
              class="ma-2 ma-md-5 d-flex flex-column"
              width="450"
              min-height="350"
              :elevation="hover ? 12 : 0"
            >
              <v-img
                :src="img_url(article.image)"
                height="200px"
                width="100%"
                aspect-ratio="16/9"
                cover
              ></v-img>

              <v-card-title style="word-break: keep-all" class="px-8">
                {{ article.title }}
              </v-card-title>

              <v-card-subtitle class="px-8 mt-auto">
                {{ formatDate(article.createdAt) }}
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </template>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import breakpoint from "../../mixins/breakpoint";
import { mapState } from "vuex";
export default {
  mixins: [breakpoint],
  data: () => ({
    loading: false,
    selection: 1,
    // news_link: [
    //   '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FMDMedicalCares%2Fposts%2F570524164638374&show_text=true&width=500" width="500" height="443" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>',
    //   '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FMDMedicalCares%2Fposts%2F501740058183452&show_text=true&width=500" width="500" height="757" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>',
    //   '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FMDMedicalCares%2Fposts%2F490596632631128&show_text=true&width=500" width="500" height="400" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>',
    //   '<iframe src="https://www.facebook.com/plugins/video.php?height=315&href=https%3A%2F%2Fwww.facebook.com%2FMDMedicalCares%2Fvideos%2F916517708940195%2F&show_text=true&width=560&t=0" width="560" height="430" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>',
    // ],
  }),

  // async created() {
  //   this.loading = true;
  //   this.articles = await this.$get("newsblog");
  //   this.loading = false;
  // },
  computed: {
    ...mapState(["articles"]),
  },
  methods: {
    show_details(id) {
      this.$router.push({ name: "article-details", params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
/* .news__container {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 0) 65%,
      rgba(255, 255, 255, 1) 85%
    ),
    url("@/assets/world-map.png");

} */
.news__container {
  background: url("@/assets/world-map.png");
  // min-height: 100vh;
  // background-size: cover;
  background-repeat: repeat-x;
  background-position: center;
}
</style>
