<template>
  <v-container fluid>
    <v-row
      id="hero"
      class="d-flex"
      align-content="center"
      :style="{ height: '100vh' }"
      v-if="!loading"
    >
      <v-col id="hero-card" xs="12" lg="9" class="px-0">
        <!-- <div class="slider-circle-shape mt-n10"></div> -->
        <div style="position: relative; z-index: 1">
          <div
            data-aos="fade-right"
            data-aos-anchor-placement="top-bottom"
            class="font-weight-black text-capitalize white--text text-h5 text-md-h4 text-xl-h2"
            style="line-height: 100%; text-shadow: 0 0 10px #00000080"
          >
            Provider Of
            <br v-if="breakpoint.xsOnly" />
            Cutting-Edge
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="50"
          >
            <div
              class="font-weight-black text-capitalize text-h3 text-md-h2 text-xl-h1 my-2"
              style="text-shadow: 0 0 20px #ffffff80; color: #202673"
            >
              Medical Technology
            </div>
            <p
              class="white--text font-weight-medium text-capitalize pr-6 pr-sm-0 text-body-2 text-lg-body-1 text-xl-h6"
              style="text-shadow: 0 0 10px #00000085"
            >
              <!-- text-shadow: 0 0 20px #000000; -->
              <!-- We build life-long partnerships with our customers by providing
            them<br v-if="breakpoint.smAndUp" />
            cutting-edge medical technology in healthcare and superior after<br
              v-if="breakpoint.smAndUp"
            />
            sales service that is always dependable and constantly available. -->
              Caring for patients is our passion; Accuracy is our priority;
              <br v-if="breakpoint.smAndUp" />
              Our diagnostic instruments are our guarantee.
            </p>
          </div>
          <div
            data-aos="fade-up-right"
            data-aos-anchor-placement="bottom"
            data-aos-delay="0"
            class="mt-10 d-flex flex-wrap justify-center justify-sm-start"
          >
            <v-btn
              elevation="0"
              class="rounded-xl ma-2 text-button font-weight-bold font-italic"
              width="270"
              min-height="50"
              :x-large="breakpoint.mdAndUp"
              :large="breakpoint.sm"
              outlined
              @click="$vuetify.goTo('#contact')"
              color="white"
            >
              Get a Quote Today!
            </v-btn>
            <v-btn
              elevation="0"
              class="rounded-xl ma-2 white--text text-button font-weight-bold font-italic"
              width="270"
              min-height="50"
              :x-large="breakpoint.mdAndUp"
              :large="breakpoint.sm"
              color="theme"
              @click="$vuetify.goTo('#products')"
            >
              Explore Products
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-carousel
        :height="'100vh'"
        id="hero-img"
        :style="{
          width: '110vw',
        }"
        :show-arrows="false"
        hide-delimiters
        touchless
        continuous
        mandatory
        cycle
      >
        <transition-group name="fade" tag="div">
          <v-carousel-item
            eager
            v-for="(item, i) in hero"
            :key="i"
            :transition="false"
            class="carousel-img"
            active-class="zoom-img"
          >
            <!-- <v-overlay
              class="overlay"
              :value="true"
              absolute
              :key="`overlay-${i}`"
            ></v-overlay> -->
            <!-- color="linear-gradient(180deg, #010c2900 24.66%, #010b28 100%)" -->
            <!-- color="rgb(32 38 115 / 50%)" -->
            <!-- color="rgb(32 38 115 / 60%)" -->
            <v-img
              eager
              :src="img_url(item)"
              :lazy-src="img_url(item)"
              height="calc(100vh + 150px)"
            >
              <div class="fill-height hero-gradient"></div>
            </v-img>
          </v-carousel-item>
        </transition-group>
      </v-carousel>
    </v-row>
  </v-container>
</template>

<script>
import breakpoint from "../../mixins/breakpoint";
import { mapState } from "vuex";
// import heroImg from "@/components/heroImg.vue";
export default {
  mixins: [breakpoint],
  // components: { heroImg },
  data() {
    return {};
  },
  computed: {
    ...mapState(["loading", "hero"]),
  },
};
</script>

<style lang="scss">
.hero-gradient {
  // width: 100%;
  background-image: linear-gradient(
    135deg,
    rgba(25, 41, 134, 0.4) 35%,
    rgba(101, 182, 230, 0.4) 55%
  ) !important;
  // rgba(25, 41, 134, 0.4) 95%

  backdrop-filter: blur(2.5px) brightness(1.1);
}
#hero-img {
  z-index: 0;
  position: absolute;
  top: 0%;
  right: 0%;
}
#hero-card {
  padding: 12vh 5vw 12vh 2vw;
  border-radius: max(0px, min(12px, (100% - 400px + 1px) * 9999)) / 16px;
}
.zoom-img {
  animation: zoom-in 60s ease;
}
.slider-circle-shape {
  height: 350px;
  width: 350px;
  position: absolute;
  left: 20vw;
  z-index: 0;
  border-radius: 50%;
  background: #e8eaf6;
  /* top: 0; */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s ease;
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
</style>
