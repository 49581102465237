import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProductDetails from "../components/pages/productDetails.vue";
import NewsArticles from "../components/pages/articlesDetails.vue";
import principalProducts from "@/components/pages/principalProducts.vue";
import CareerDetails from "../components/pages/careerDetails.vue";
import AboutView from "../views/AboutView.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import p404 from "../views/Route404View.vue";
// import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [],
  },
  {
    name: "product-details",
    path: "/products/:id",
    component: ProductDetails,
  },
  {
    name: "career-details",
    path: "/career/:id",
    component: CareerDetails,
  },
  {
    name: "article-details",
    path: "/news/:id",
    component: NewsArticles,
  },
  {
    name: "principal-products",
    path: "/parner/:name",
    component: principalProducts,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "*",
    name: "404",
    component: p404,
  },
];

const router = new VueRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },

  mode: "history",
  // base: process.env.VUE_APP_BASE_URL,
  routes,
});

export default router;
