<template>
  <v-card elevation="0" width="100%" class="careers__container">
    <v-card
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-delay="200"
      :max-width="breakpoint.mdAndUp ? '650px' : '100vw'"
      class="mx-auto"
      flat
    >
      <v-card-title
        class="text-center d-block px-0 py-5 pa-sm-4 text-h4 font-weight-black"
      >
        CAREERS
        <!-- <div class="d-flex mt-8"> -->
        <v-card-text
          class="text-center py-0 px-4 pa-sm-4"
          style="word-break: keep-all"
        >
          <div class="text-h5 font-weight-bold mt-4 mb-2">Why join us?</div>
          <p class="text-body-1 text-center">{{ mission_statement }}</p>
        </v-card-text>
        <v-card-text class="text-center pa-0 pa-sm-4 mt-4">
          <div class="text-body-1 font-weight-bold mb-2">
            Be part of Marsman Medical Family and enjoy the following benefits!
          </div>
          <div class="d-flex flex-wrap justify-center pa-0">
            <v-chip
              class="text-caption text-capitalize text-sm-body-2 font-weight-medium"
              v-for="benifit in benifits"
              :key="benifit"
              label
              style="overflow-wrap: break-word"
              color="transparent"
            >
              <v-icon left> mdi-circle-small </v-icon>
              {{ benifit }}
            </v-chip>
          </div>
          <!-- <ul>
            <li v-for="benifit in benifits" :key="benifit">
              <p style="word-break: keep-all">{{ benifit }}</p>
            </li>
          </ul> -->
        </v-card-text>
        <!-- </div> -->
      </v-card-title>
    </v-card>
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top"
      data-aos-delay="400"
      data-aos-duration="500"
    >
      <v-card-title
        ref="careers"
        class="d-block text-center py-5"
        style="word-break: keep-all"
      >
        <div
          v-if="careers && careers.length > 0"
          class="d-flex flex-wrap flex-row justify-center"
        >
          <h4 class="text-h4 font-weight-black text-uppercase">
            Open positions in
          </h4>
          <span>
            <v-select
              class="location-select pr-auto text-subtitle-1 font-weight-black"
              v-model="selected_location"
              :items="locations"
              item-color="indigo"
              color="indigo"
              dense
              solo
              flat
              hide-details
            >
              <template v-slot:item="data">
                <span class="text-capitalize">{{ data.item }}</span>
              </template>
            </v-select>
          </span>
        </div>
      </v-card-title>

      <v-btn-toggle
        v-model="section_tab"
        @change="slide_item = null"
        tile
        color="theme"
        mandatory
        class="d-flex flex-wrap justify-center mb-5"
        group
      >
        <v-btn
          v-for="(department, key) in departments"
          :key="key"
          class="font-weight-black d-flex px-0 my-0 mx-2"
          :ripple="false"
          plain
          :small="breakpoint.mdAndDown"
        >
          <span class="text-capitalize">
            {{ department }}
          </span>
        </v-btn>
      </v-btn-toggle>
      <v-tabs-items v-model="section_tab" continuous>
        <v-tab-item
          v-for="(department, key) in sorted_careers"
          :key="key"
          class="pb-2"
          eager
        >
          <v-card
            color="transparent"
            class="d-flex flex-wrap justify-center"
            flat
            tile
          >
            <!-- //.startsWith(career.location.toLocaleLowerCase()) -->
            <template v-for="(career, key) in department">
              <!-- :class="{
                'd-none':
                  career.location !== selected_location ||
                  selected_location == 'all locations',
              }" -->
              <v-hover
                :key="key"
                v-if="
                  career.location
                    .toLocaleLowerCase()
                    .startsWith(selected_location.toLocaleLowerCase()) ||
                  selected_location == 'all locations'
                "
              >
                <template v-slot:default="{ hover }">
                  <v-card
                    :style="{ 'background-color': 'white' }"
                    class="ma-4"
                    min-width="250"
                    max-width="350"
                    :elevation="hover ? 3 : 0"
                    @click="getDetails(career._id)"
                    outlined
                    tile
                  >
                    <v-card-title class="text-body-2 font-weight-bold">
                      {{ career.title }}
                    </v-card-title>
                    <v-card-subtitle class="text-uppercase">
                      <v-icon dense small> mdi-map-marker </v-icon>
                      {{ career.location }}
                    </v-card-subtitle>
                  </v-card>
                </template>
              </v-hover>
            </template>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import breakpoint from "../../mixins/breakpoint";
import { mapState } from "vuex";
export default {
  mixins: [breakpoint],
  data() {
    return {
      selected_location: "all locations",
      benifits: [
        "Bonuses",
        "HMO, Group Life and Accident Insurance",
        "Leaves (statutory and special leave benefits)",
        "Optical Benefits and Medicine Allowance",
        "Scholarship Program (Employee & Dependent)",
        "And many more...",
      ],
      mission_statement:
        "We nurture a workplace climate where collaboration and growth are encouraged; continuous innovation and excellent performance are rewarded; with due care for the environment",
      slide_item: null,
      section_tab: -1,
    };
  },
  methods: {
    getDetails(id) {
      this.$router.push({ name: "career-details", params: { id } });
    },
  },
  computed: {
    ...mapState(["careers"]),
    departments() {
      return Object.keys(this.sorted_careers);
      //   return ["all positions", "sales", "technical", "admin"];
    },
    locations() {
      return ["all locations", "NCR", "vis", "min"];
    },
    sorted_careers() {
      if (this.careers && this.careers.length > 0) {
        return this.careers.reduce(
          (dept, pos) => {
            const { department } = pos;
            dept[department] = dept[department] ?? [];
            dept[department].push(pos);
            return dept;
          },
          { "all positions": this.careers }
        );
      } else return [];
    },
    // selected_section() {
    //   const selected_section = this.sections[this.section_tab];
    //   const section_items = this.products[selected_section];
    //   return section_items;
    // },
  },
};
</script>

<style lang="scss">
#careers .careers__container {
  .v-card {
    background-color: rgba(255, 255, 255, 0.85);
  }
  .theme--light.v-tabs-items {
    background-color: unset; //rgba(255, 255, 255, 0.4);
  }
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.75) 40%,
      rgba(255, 255, 255, 1) 100%
    ),
    url("@/assets/paper-background.jpg");
  // min-height: 100vh;
  background-size: cover;
  // background-repeat: repeat-x;
  background-position: center;
}
#careers .v-select__selection.v-select__selection--comma {
  color: #202673;
  text-decoration: underline;
}
#careers .location-select .v-input__slot {
  padding-right: 12px;
  background-color: transparent;
}
#careers
  .location-select
  .v-input__slot
  .v-select__selection.v-select__selection--comma {
  margin-right: 16px;
}
#careers .location-select .v-input__slot .v-input__append-inner {
  padding-left: 4px;
}
#careers
  .location-select
  .v-input__slot
  .v-input__append-inner
  .v-input__icon.v-input__icon--append {
  width: 24px;
  min-width: 24px;
}
.location-select .v-select__selections input {
  display: none;
  /* width: 200px; */
  flex-basis: fit-content;
}
.location-select.v-input
  .v-input__control
  .v-select__slot
  .v-input__append-inner {
  margin-left: -18px;
}
</style>
