<template>
  <v-container style="margin-top: 60px" class="pa-0" fluid>
    <!-- <divstyle="background-image: url('@/assets/banner.png')"></div> -->
    <v-img
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      class="bg"
      min-height="50vh"
      position="center center"
      max-height="210"
      v-if="about_contents && about_contents.banner"
      :lazy-src="img_url(about_contents.banner)"
      :src="img_url(about_contents.banner)"
    >
      <div
        class="d-flex justify-center align-center"
        :style="{
          height: '100%',
          textShadow: '0 0 20px #000000',
        }"
      >
        <h1 class="text-h3 white--text font-weight-bold">About Us</h1>
      </div>
    </v-img>
    <v-card class="px-6 px-sm-9 py-16 mx-auto" flat max-width="1368">
      <v-card-title class="text-center d-block text-h4 font-weight-medium">
        Our Business
      </v-card-title>
      <v-row class="mt-12">
        <template v-if="gridItems.length > 1">
          <v-col
            v-for="(item, key) in gridItems"
            class="col-12 col-md-6 ma-auto pa-4 d-flex"
            align-self="center"
            :key="key"
          >
            <v-img
              v-if="item.type == 'image'"
              class="my-auto lobby rounded-lg"
              contain
              :src="img_url(item.src)"
              :lazy-src="img_url(item.src)"
            >
              <!-- :src="`${api_url}${item.src.replaceAll('\\', '/')}`"
              :lazy-src="`${api_url}${item.src.replaceAll('\\', '/')}`" -->
            </v-img>
            <p v-else class="my-auto text-justify order-md-first">
              <br />
              {{ item.content }}
            </p>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <div
      class="d-flex flex-wrap py-16 px-md-8 px-2"
      :style="{ height: `${$vuetify.breakpoint.mdAndUp ? '100vh' : 'unset'}` }"
    >
      <div
        v-for="(panel, key) in vmv"
        :key="key"
        class="mvv-container mx-auto mt-8"
        :style="{ width: $vuetify.breakpoint.smAndDown ? '96vw' : '30vw' }"
      >
        <v-img
          v-if="about_contents && about_contents[`${key}_bg`]"
          :src="img_url(about_contents[`${key}_bg`])"
          :lazy-src="img_url(about_contents[`${key}_bg`])"
          class="mvv-img"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          width="100%"
        >
        </v-img>
        <v-card
          :color="panel.color"
          class="mx-auto rounded-lg mvv-body"
          dark
          height="100%"
        >
          <v-card-title
            class="d-flex flex-column mb-4 mb-md-0 mb-lg-4 text-center text-uppercase"
          >
            <span class="text-h3 text-md-h4 text-lg-h3 font-weight-bold">
              OUR
            </span>
            <span class="text-h3 text-md-h4 text-lg-h3 font-weight-bold">
              {{ key }}
            </span>
          </v-card-title>
          <v-card-text
            :class="{
              'text-center': key == 'vision',
            }"
            class="px-8 text-justify text-lg-body-1 text-md-body-2 white--text"
            v-html="panel.text"
          ></v-card-text>
          <v-img
            v-if="key == 'values'"
            class="values mx-auto"
            :src="values"
            :lazy-src="values"
            max-width="80%"
            aspect-ratio="1"
            contain
          ></v-img>
        </v-card>
      </div>
    </div>
    <v-card flat class="pt-12 d-block">
      <section-partners></section-partners>
    </v-card>
  </v-container>
</template>

<script>
import values from "@/assets/values.png";
import SectionPartners from "@/components/sections/SectionPartners";
import breakpoint from "@/mixins/breakpoint";

export default {
  mixins: [breakpoint],
  components: {
    SectionPartners,
  },
  data() {
    return {
      values,
      vmv: {
        mission: {
          color: "#b54040cc",
          text: "<h3>Acknowledging God as the source of all good …</h3><p></p><ul><li><p>We build enduring partnerships with our customers by providing high-quality competitive medical products and technology; sustained with superior and dependable service.</p></li><li><p>We sustain a mutually beneficial relationship with our principals, leveraging on their cutting-edge technology and technical support.</p></li><li><p>We nurture a workplace climate where collaboration and growth are encouraged; continuous innovation and excellent performance are rewarded; with due care for the environment.</p></li><li><p>We equitably share the rewards of success with our employees, community, and shareholders.</p></li></ul>",
        },
        // values:
        //   "<h3>Doing our best to follow His guidance in all that we do.</h3><p></p><p>Marsman Drysdale Medical Products, Inc’s culture is defined by our core values, a set of principles, and fundamental beliefs that helps the company in functioning together as a team and work together toward the achievement of our business goals. It is rooted in the values of I-TEAM as:</p><ol><li><p>Integrity</p></li><li><p>Teamwork</p></li><li><p>Excellence</p></li><li><p>Accountability</p></li><li><p>Malasakit</p></li></ol>",
        values: {
          color: "#418246cc",
          text: "<h3>Doing our best to follow His guidance in all that we do.</h3><p></p><p>Marsman Drysdale Medical Products, Inc’s culture is defined by our core values, a set of principles, and fundamental beliefs that helps the company in functioning together as a team and work together toward the achievement of our business goals. It is rooted in the values of I-TEAM as:</p>",
        },
        vision: {
          color: "#464182cc",
          text: "<p>“To become the Philippines’ leading provider of IVD tests and most preferred partner in delivering diagnostic solutions to the patients.”</p>",
        },
      },
      about_contents: {},
    };
  },
  computed: {
    about_paragraph() {
      let parser = new DOMParser();
      let doc = parser.parseFromString(
        this.about_contents.content,
        "text/html"
      );
      return [...doc.getElementsByTagName("p")];
    },
    gridItems() {
      // return this.about_contents.images;
      if (this.about_contents && this.about_contents.images) {
        const combinedArray = [];
        const maxItems = Math.max(
          this.about_paragraph.length,
          this.about_contents.images.length
        );
        for (let i = 0; i < maxItems; i++) {
          if (i % 2 !== 0) {
            if (this.about_paragraph[i]) {
              combinedArray.push({
                type: "paragraph",
                content: this.about_paragraph[i].innerHTML,
              });
            }

            if (this.about_contents.images[i]) {
              combinedArray.push({
                type: "image",
                src: this.about_contents.images[i],
                alt: this.about_contents.images[i],
              });
            }
          } else {
            if (this.about_contents.images[i]) {
              combinedArray.push({
                type: "image",
                src: this.about_contents.images[i],
                alt: this.about_contents.images[i],
              });
            }
            if (this.about_paragraph[i]) {
              combinedArray.push({
                type: "paragraph",
                content: this.about_paragraph[i].innerHTML,
              });
            }
          }
        }

        return combinedArray;
      } else {
        return [];
      }
    },
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  async created() {
    const data = await this.$get("/settings/about-us");
    data.forEach((element) => {
      this.$set(
        this.about_contents,
        element.name.replace("about_", ""),
        element.value
      );
    });
  },
};
</script>

<style>
.bg .v-image__image {
  filter: blur(0.5px);
  /* 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /* image-rendering: auto; */
}
/* .lobby .v-image__image {
  filter: blur(0.5px);
} */
.mvv-container {
  display: grid;
  grid-template-rows: 120px 660px 120px;
  grid-template-columns: 10% 80% 10%;
  grid-gap: 0;
}
.mvv-img {
  grid-row: 2 / 4;
  grid-column: 1 / 4;
}
.mvv-body {
  z-index: 1;
  grid-row: 1 / 3;
  grid-column: 2 / 2;
}
</style>
