<template>
  <v-app>
    <v-scroll-y-transition>
      <app-bar v-if="!loading" />
    </v-scroll-y-transition>
    <v-main class="pt-0">
      <router-view />
    </v-main>
    <app-footer v-if="!loading" />
  </v-app>
</template>

<script
  type="application/javascript"
  src="https://api.ipify.org/?format=json"
></script>
<script>
import { mapActions, mapState } from "vuex";
import AppFooter from "@/components/AppFooter.vue";
import AppBar from "@/components/AppBar.vue";
export default {
  components: { AppFooter, AppBar },
  computed: {
    ...mapState(["loading"]),
  },
  created() {
    // window.history.scrollRestoration = "manual";
  },
  methods: {
    ...mapActions(["init", "action_update_loading"]),
    observeSections() {
      // try {
      //   this.sectionObserver.disconnect();
      // } catch (error) {
      //   console.log(error);
      // }

      const options = {
        rootMargin: "0px 0px -90% 0px",
        threshold: 0,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          const sectionId = entry.target.id;
          if (this.$route.hash !== `#${sectionId}`) {
            this.$router.push({
              name: this.$route.name,
              hash: `#${sectionId}`,
            });
          }
        }
      }
    },
  },
  async mounted() {
    window.addEventListener("load", this.action_update_loading(false));
    this.observeSections();
    await this.init();
    //this.$post("visit");
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 100%;
}
.v-application ul li p {
  margin-bottom: 4px;
}
.v-application ul {
  margin-bottom: 16px;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  margin: 1%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
