<template>
  <div
    :class="{
      'background-sm': breakpoint.mdAndDown,
      'background-lgAndUp': breakpoint.lgAndUp,
    }"
    class="about-container"
  >
    <div class="mx-auto pb-10 d-flex align-center justify-center flex-wrap">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        style="width: 100vw"
        class="d-flex flex-column justify-start flex-grow-1 flex-shrink-0"
      >
        <div class="mx-auto text-subtitle-1 font-weight-bold theme--text">
          About Us
        </div>
        <p
          class="mx-auto px-8 text-h4 text-sm-h3 font-weight-black text-uppercase text-center"
        >
          we are trusted by more<br v-if="breakpoint.smAndUp" />
          than
          <span class="theme--text text-uppercase"> 400 clients </span>
        </p>
      </div>
      <div class="d-flex fill-height mx-4 flex-shrink-1">
        <v-img
          :data-aos="breakpoint.mobile ? 'fade-up' : 'fade-right'"
          data-aos-anchor-placement="top-center"
          class="mx-auto d-flex about-container__collage"
          width="600px"
          aspect-ratio="1"
          :src="img_url(about_image)"
          :lazy-src="img_url(about_image)"
          eager
        >
          <!-- max-height="600px" -->
          <v-card
            class="rounded-lg pa-8 d-inline-flex ma-auto"
            dark
            color="theme"
          >
            <div class="text-h6 text-center text-uppercase font-weight-black">
              {{ years }} years of <br />
              medical <br />
              supremacy
            </div>
          </v-card>
        </v-img>
      </div>

      <div class="d-flex fill-height px-4">
        <v-card
          :data-aos="breakpoint.mobile ? 'fade-up' : 'fade-left'"
          data-aos-anchor-placement="top-center"
          color="transparent"
          elevation="0"
          :max-width="breakpoint.mdAndUp ? '500' : '600'"
        >
          <!-- :style="{
            'padding-right': breakpoint.lgAndUp ? '200px' : 'auto',
          }" -->

          <v-card-text
            style="text-shadow: 1px 1px 15px #ffffff"
            class="text-body-1 text--grey text-darken-4 font-weight-medium d-flex flex-column text-justify"
          >
            <div class="mb-4">
              <iframe
                src="https://drive.google.com/file/d/12_ouHbSmYgTzsFyyQqa2GCvPdla3yuIq/preview"
                width="100%"
                height="258"
                allow="autoplay"
              ></iframe>
            </div>
            <div
              style="background-color: rgba(255, 255, 255, 0.3)"
              class="pa-4 rounded-lg"
            >
              <p>
                We are a major provider/distributor of In-Vitro Diagnostic (IVD)
                products and services in the market for more than
                {{ years }} years.
              </p>

              <p class="mb-0">
                The product range that we carried is in the following medical
                diagnostic areas:
                <strong>
                  Hematology, Immunology, Chemistry, Special Chemistry,
                  Coagulation, Flowcytometry, Urine Analysis and Laboratory
                  Information System (LIS).
                </strong>
              </p>
            </div>
            <v-btn
              elevation="0"
              class="rounded-pill ma-2 white--text text-button font-weight-bold font-italic"
              width="270"
              min-height="50"
              :x-large="breakpoint.mdAndUp"
              :large="breakpoint.sm"
              color="theme"
              @click="$router.push({ name: 'about' })"
            >
              Learn More
            </v-btn>
          </v-card-text>
          <!-- <v-btn
          elevation="0"
          x-large
          color="theme"
          @click="$router.push({ name: 'about' })"
          style="letter-spacing: 0.2em"
          class="button font-weight-medium white--text ml-4"
        >
          Learn More
        </v-btn> -->
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import breakpoint from "../../mixins/breakpoint";
// import about from "@/assets/about.jpg";
export default {
  mixins: [breakpoint],
  data() {
    return {
      // about,
    };
  },
  computed: {
    ...mapState(["about_image"]),
    years() {
      return new Date().getFullYear() - 1997;
    },
  },
};
</script>

<style lang="scss">
#about .about-container {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__collage .v-responsive__content {
    display: flex;
  }
}
.background-sm {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 0.2) 65%,
      rgba(255, 255, 255, 1) 85%
    ),
    url("@/assets/Building.jpg");
}
.background-lgAndUp {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 75%
    ),
    url("@/assets/Building.jpg");
}
.since-old {
  z-index: 1;
  position: absolute;
  border-radius: 8px;
  text-align: center;
  padding: 60px 10px;
  margin: auto;
  z-index: 1;
  width: 175px;
}
.since {
  position: absolute;
  text-align: center;
  color: white;
  top: 50%;
  right: 0;
  left: 0;
  width: fit-content;
  /* padding: 50px 30px; */
  padding: 8%;
  margin: auto;
  border-radius: 10px;
  transform: translateY(-50%);
}
</style>
