<template>
  <v-app-bar
    app
    fixed
    scroll-threshold="130"
    color="white"
    :min-height="breakpoint.smAndDown ? 60 : 72"
    class="py-0"
    :class="{ 'px-2': breakpoint.smAndDown, 'px-9': breakpoint.mdAndUp }"
    :elevate-on-scroll="isHomePage"
  >
    <!-- :prominent="isHomePage"
    :shrink-on-scroll="isHomePage"
    :color="isTransparent" -->
    <span class="d-block my-auto">
      <img
        @click="reload"
        style="cursor: pointer"
        class="mr-3"
        :height="breakpoint.mdAndUp ? 40 : 35"
        :src="require('../assets/name-logo.png')"
      />
    </span>
    <h3 v-if="br">
      {{ br }}
    </h3>
    <v-spacer></v-spacer>
    <span class="my-auto">
      <v-list v-if="breakpoint.mdAndUp" class="d-flex nav-bar-items">
        <!-- :to="`/#${item.tag}`" -->
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :href="`#${item.tag}`"
          :ripple="false"
          :input-value="`#${item.tag}` == $route.hash"
          class="mx-auto"
          active-class="active-nav"
          @click="goTo(item.tag)"
        >
          <v-list-item-title
            tile
            class="nav-label text-capitalize"
            v-text="item.title"
          />
          <!-- <v-btn :ripple="false" plain tile>
            <span class="font-weight-black black--text text-uppercase">
              {{ item.title }}
            </span>
          </v-btn> -->
        </v-list-item>
        <!-- <v-btn
          v-for="item in items"
          :key="item.title"
          :ripple="false"
          plain
          tile
        >
          <span class="font-weight-black black--text text-uppercase">
            {{ item.title }}
          </span>
        </v-btn> -->
        <!-- <router-link to="/#news">News</router-link> -->
      </v-list>
      <v-menu bottom left v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
            <!-- color="grey darken-4" -->
            <!-- <v-icon>mdi-dots-vertical</v-icon> -->
          </v-app-bar-nav-icon>
        </template>
        <!-- <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            large
            color="grey darken-4"
          ></v-app-bar-nav-icon> -->
        <v-list class="py-2 px-5">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-title
              @click="goTo(item.tag)"
              class="text-capitalize text-center"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
  </v-app-bar>
</template>

<script>
import breakpoint from "../mixins/breakpoint";
import { throttle } from "throttle-debounce";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      show_fab: false,
      scroll_var: 0,
      items: [
        { title: "home", tag: "hero" },
        { title: "about us", tag: "about" },
        { title: "products", tag: "products" },
        { title: "news", tag: "news" },
        { title: "careers", tag: "careers" },
        { title: "contact us", tag: "contact" },
      ],
    };
  },
  mixins: [breakpoint],
  methods: {
    ...mapMutations(["toggle_fab"]),
    reload() {
      // location.reload();
      if (this.$route.name == "home") this.$vuetify.goTo(`#hero`, {});
      else this.$router.replace({ name: "home" });
    },
    handleScroll() {
      // console.log(window.scrollY);
      this.show_fab = window.scrollY > window.innerHeight / 3 ? true : false;
      this.scroll_var = window.scrollY;
    },
    goTo(hash) {
      // console.log("this.goTo", hash);
      // if (this.$route.name !== "home") {
      if (this.$route.hash !== `#${hash}`) {
        this.$router.push({ path: "/", hash: `#${hash}` });
      }
      //   setTimeout(() => {
      //     this.$vuetify.goTo(`#${hash}`, {});
      //   }, 250);
      // } else {
      //   setTimeout(() => {
      //     if (this.$route.hash !== `#${hash}`) {
      //       this.$router.push({ hash: `#${hash}` });
      //       this.$vuetify.goTo(`#${hash}`, {});
      //     }
      //   }, 200);
      // }
      // console.log(hash, this.$route.name);
    },
  },
  mounted() {
    // let handleDebounce = debounce(this.handleScroll, 100);
    let handleDebounce = throttle(200, this.handleScroll);
    window.addEventListener("scroll", handleDebounce);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    show_fab(val) {
      this.toggle_fab(val);
    },
  },
  computed: {
    isHomePage() {
      return this.$route.name === "home";
    },
    br() {
      return process.env.VUE_APP_NODE_ENV == "dev"
        ? `${this.$vuetify.breakpoint.name} ` //- mobile: ${this.$vuetify.breakpoint.mobile}`
        : false;
    },
    app_height() {
      return document.querySelector("header").clientHeight;
    },
    isTransparent() {
      // return false; //
      return this.scroll_var <= 10 ? "transparent" : "white";
    },
  },
};
</script>
<style>
.v-toolbar__content {
  height: 100% !important;
}
.nav-bar-items button {
  letter-spacing: 0.01em;
  /* font-size: 32px; */
}
.active-nav {
  /* background-color: white !important; */
  /* opacity: unset !important; */
  /* color: black !important; */
  font-weight: 900;
}
</style>
<style scoped>
.nav-bar-items .theme--light.v-list-item--active:before {
  opacity: 0 !important;
  text-decoration: underline;
}
</style>
