<template>
  <v-container fluid>
    <v-card flat style="margin-top: 128px" class="mx-8">
      <v-card-title class="font-weight-bold"
        >Terms &amp; Conditions</v-card-title
      >
      <v-card-text class="">
        <p>
          By using the app, these terms will automatically apply to you – you
          should make sure therefore that you read them carefully before using
          the app. You’re not allowed to copy or modify the app, any part of the
          app, or our trademarks in any way. You’re not allowed to attempt to
          extract the source code of the app, and you also shouldn’t try to
          translate the app into other languages or make derivative versions.
          The app itself, and all the trademarks, copyright, database rights,
          and other intellectual property rights related to it, still belong to
          Marsman Drysdale Medical Products, Inc..
        </p>
        <p>
          Marsman Drysdale Medical Products, Inc. is committed to ensuring that
          the app is as useful and efficient as possible. For that reason, we
          reserve the right to make changes to the app or to charge for its
          services, at any time and for any reason. We will never charge you for
          the app or its services without making it very clear to you exactly
          what you’re paying for.
        </p>
        <p>
          The Website app stores and processes personal data that you have
          provided to us, to provide our Service. It’s your responsibility to
          keep your phone and access to the app secure. We therefore recommend
          that you do not jailbreak or root your phone, which is the process of
          removing software restrictions and limitations imposed by the official
          operating system of your device. It could make your phone vulnerable
          to malware/viruses/malicious programs, compromise your phone’s
          security features and it could mean that the Website app won’t work
          properly or at all.
        </p>
        <!---->
        <p>
          You should be aware that there are certain things that Marsman
          Drysdale Medical Products, Inc. will not take responsibility for.
          Certain functions of the app will require the app to have an active
          internet connection. The connection can be Wi-Fi or provided by your
          mobile network provider, but Marsman Drysdale Medical Products, Inc.
          cannot take responsibility for the app not working at full
          functionality if you don’t have access to Wi-Fi, and you don’t have
          any of your data allowance left.
        </p>
        <p></p>
        <p>
          With respect to Marsman Drysdale Medical Products, Inc.’s
          responsibility for your use of the app, when you’re using the app,
          it’s important to bear in mind that although we endeavor to ensure
          that it is updated and correct at all times, we do rely on third
          parties to provide information to us so that we can make it available
          to you. Marsman Drysdale Medical Products, Inc. accepts no liability
          for any loss, direct or indirect, you experience as a result of
          relying wholly on this functionality of the app.
        </p>
        <p><strong>Changes to This Terms and Conditions</strong></p>
        <p>
          We may update our Terms and Conditions from time to time. Thus, you
          are advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Terms and Conditions on
          this page.
        </p>
        <p>These terms and conditions are effective as of 2022-07-22</p>
        <p><strong>Contact Us</strong></p>
        <p>
          If you have any questions or suggestions about our Terms and
          Conditions, do not hesitate to contact us at
          mdmpi@marsmandrysdale.com.
        </p>
        <p>
          This Terms and Conditions page was generated by
          <a
            href="https://app-privacy-policy-generator.nisrulz.com/"
            target="_blank"
            rel="noopener noreferrer"
            >App Privacy Policy Generator</a
          >
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
